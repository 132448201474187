//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgdown from '@/assets/icon-down.png'
import imgdate from '@/assets/icon-date.png'
import gsap from "gsap";
export default {
  name: "storeDailyReportIndex",
  data() {
    return {
      imgdown,
      imgdate,
      storeOption: [],
      storeInfo:{},
      roleList:[],
      dialogFormVisible: true,
      storeListLoading: false,
      storesOptions: [],
      curStore: '',
      storeCode: "",
      storeName:"",
      month:new Date().getMonth(),
      year:new Date().getFullYear(),
      years:[],
      dailyList: [],
      noCacheItem:null,//最近一次提交项
      cacheItem:null,//最近一次缓存项
      monthSales:0,
      monthSalesCompleteRate:0,
      yearSales:0,
      yearSalesCompleteRate:'0%',
      YD_AmountIndex:0,
      showMonthAmount:0,
      showMonthPercent:0,
    };
  },
  watch: {
    monthSales(newValue) {
      console.log(newValue);
      gsap.to(this, {duration: 1, showMonthAmount: newValue})
    },
    monthSalesCompleteRate(newValue) {
      console.log(newValue);
      gsap.to(this, {duration: 1, showMonthPercent: newValue})
    }
  },
  created() {
    this.years = new Array(new Date().getFullYear() - 2020).fill(2021).map((v,i)=>{
      return v + i
    })
    // this.storeName = this.$route.query.storeName;
    // this.storeCode = this.$route.query.storeCode;
    window.document.title = '门店日报';
     
    
  },
  activated() {
    this.getUserInfo();
    
  },
  methods: {
      filterFn(val, update, abort) {
        update(() => {
            const needle = val.toUpperCase();
            this.storesOptions = this.storeOption.filter(function(v) {
                let result = v.storeId.toUpperCase().indexOf(needle) > -1;
                let result1 = v.title.indexOf(needle) > -1;
                if (result || result1) {
                    return true;
                }
                return false;
            })
        })
    },
    handleSelectStore: function() {
        if (!this.curStore || this.curStore.length == 0) {
            this.$dialog.alert({
                message: '请先选择门店',
            });
            return;
        }
        console.log(this.curStore)
        this.storeName = this.curStore.text;
        this.storeCode = this.curStore.storeId;
        this.dialogFormVisible = false;
        this.getStoreInfo(this.storeCode)

    },
    storeChange(value) {
      console.log(value);
      this.storeCode = value
      this.getStoreInfo(value)
      this.storeOption.map(v =>{
        if(v.storeId == value){
          this.storeName = v.text
          window.document.title = v.text
        }
      })
    },
     getUserInfo() {
      this.loading = true;
      const that = this;
      this.axios
        .get('/' + this.$ajaxPrefix.consumer +"/v1/wx/cp/getUserInfo")
        .then((res) => {
          console.log(res,'getUserInfo');
           that.loading = false;
          if (res && res.data && res.data.data) {
            that.roleList = res.data.data.attr.roleList;
            let saleStore = res.data.data.attr.saleStore
            //格式化下拉列表参数
            saleStore.map(v =>{
              v.text = v.storeId + '_' + v.title
              v.value = v.storeId
            })
            that.storeOption = saleStore;
            that.mobile = res.data.data.mobile;
            that.username = res.data.data.username;
            if(!that.storeCode){
              if(saleStore.length > 1){
                that.dialogFormVisible = true
              }else{
                that.dialogFormVisible = false
                this.curStore = saleStore[0];
                that.storeCode = saleStore[0].storeId
                that.storeName = saleStore[0].text
                window.document.title = saleStore[0].text
                that.getStoreInfo(that.storeCode)
              }
            }else{
              that.getStoreInfo(that.storeCode)
            }
           
            
          }
        });
    },
    getStoreInfo(storeId) {
      const that = this;
      this.axios
        .get('/' + this.$ajaxPrefix.consumer +"/v1/store/pro/getByStoreId?storeId=" + storeId)
        .then((res) => {
          console.log(res);
            that.storeInfo = res.data
            that.getDailyList()
            that.getTotalInfo()
        });
    },
    onYearItemClick(val){
      this.year = val
      this.getDailyList()
      this.getTotalInfo()
    },
    onClickTab(val) {
      this.month = val
      this.getDailyList()
      this.getTotalInfo()
    },
    addDailyReport() {
      if(this.cacheItem && this.cacheItem.isCache == 1){
        this.goDetail(this.cacheItem)
      }else{
        this.$router.push({
        name: "StoreDailyReportDetail",
        params: {
          storeName: this.storeName,
          storeCode: this.storeCode,
          minDate:this.noCacheItem && this.noCacheItem.datetime || '2021-01-01'
        },
      });
      }
      
    },
    goDetail(item) {
      this.$router.push({
        name: "StoreDailyReportDetail",
        params: {
          id:item.id,
          storeName: this.storeName,
          storeCode: this.storeCode,
          minDate: this.noCacheItem && this.noCacheItem.datetime || '2021-01-01'
        },
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getTotalInfo() {
      const that = this;
      var pdata = [{
                'PARAMETER': 'Z0CALDAY_MVIM_001',
                'SIGN': 'I',
                'OPTION': 'BT',
                'LOW': this.$moment(new Date(this.year,this.month)).startOf('month').format('yyyy-MM-DD'),
                'HIGH': this.$moment(new Date(this.year,this.month)).endOf('month').format('yyyy-MM-DD'),
                
            },  {
                'PARAMETER': 'Z0CUSTOMER_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"1",
                'LOW': that.storeCode,
                'HIGH': ''
            }]
      that.axios
        .post(
            "/consumer/bw/BwApi/BWP_ChildSaleList",
          pdata
        )
        .then((bwRes) => {
          console.log("BWP_ChildSaleList", bwRes.data);
          if (bwRes.data && bwRes.data.data && bwRes.data.data.length > 0) {
            let totalInfo = bwRes.data.data[0];
            that.YD_AmountIndex = Number(totalInfo.YD_AmountIndex).toFixed(0)
            //获取门店月累计销售
            this.axios.post("/consumer/store/daysale/getStoreMonthSalesSum", {
                "queryMap": {
                  "q_storeId": this.storeCode,
                  'q_year': this.year,
                  'q_month': this.month + 1,
                }
              })
              .then((res) => {
                console.log(1,res);
                if (res.data && res.data.data) {
                  that.monthSales = Number(res.data.data.MD_SumSales).toFixed(0);
                  if(totalInfo.MD_AmountIndex && res.data.data.MD_SumSales > 0){
                    that.monthSalesCompleteRate = (res.data.data.MD_SumSales /(totalInfo.MD_AmountIndex * 100)).toFixed(1);
                  }else{
                     that.monthSalesCompleteRate = '0%'
                  }
                  
                }
              }); 
              //获取门店年累计销售
              this.axios.post("/consumer/store/daysale/getStoreYearSalesSum", {
                "queryMap": {
                  "q_storeId": this.storeCode,
                  'q_year': this.year,
                }
              })
              .then((res) => {
                console.log(2,res);
                if (res.data && res.data.data) {
                  that.yearSales = res.data.data.YD_SumSales.toFixed(0);
                  if(totalInfo.YD_AmountIndex  && res.data.data.YD_SumSales > 0){
                    that.yearSalesCompleteRate = (res.data.data.YD_SumSales /(totalInfo.YD_AmountIndex * 100)).toFixed(1) + '%';
                  }else{
                     that.yearSalesCompleteRate = '0%'
                  }
                }
              });
          }
        });
    },
    getDailyList() {
      const that = this;
      let postData = {
        "queryMap": {
          "q_storeId": this.storeCode,
          'q_startDateTime': this.$moment(new Date(this.year,this.month)).startOf('month').format('yyyy-MM-DD'),
          'q_endDateTime': this.$moment(new Date(this.year,this.month)).endOf('month').format('yyyy-MM-DD'),
        },
        "queryMapObject": {}
      }
      this.axios
        .post("/consumer/store/daysale/storeDaySaleList", postData)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            that.dailyList = res.data.data.list.filter(v=>{
              return v.isCache == 0
            });
            that.cacheItem = res.data.data.cacheItem;
            that.noCacheItem = res.data.data.noCacheItem;
          }
        });
    },
  },
};
