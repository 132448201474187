import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=5f3eb7d6&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Index.vue?vue&type=style&index=1&id=5f3eb7d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f3eb7d6",
  null
  
)

export default component.exports
import {QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QSelect,QFooter,QMarkupTable,QBtn,QIcon,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QSelect,QFooter,QMarkupTable,QBtn,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
